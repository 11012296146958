import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Snackbar,
  CircularProgress,
  Box,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { SiteContext } from "gatsby-parallelpublicworks/src/layouts/Main";
import TermsConditionsDialog from "./TermsConditionsDialog";
import { recaptchaSubmit } from "gatsby-parallelpublicworks/src/utils";
import { Text } from "gatsby-parallelpublicworks/src/components";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "../../static/style.css";
import postContactForm from "../utils/postContactForm";

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 720,
    margin: `1rem auto 1rem auto`,
    padding: theme.spacing(3),
    "& .MuiFormLabel-root": {
      lineHeight: 1.5,
    },
  },
  inputBox: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    display: "block",
    width: "100%",
  },
  checkboxOption: {
    marginTop: theme.spacing(1),
  },
}));

function Form({ owner = null, body = null, title = "Contact us - Genetic Counselors" }) {
  const { drupalUrl } = React.useContext(SiteContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const classes = useStyles();
  const [isOpenTermsConditions, setIsOpenTermsConditions] = useState(false);
  const [snackMessage, setSnackMessage] = useState();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    institution_business_name: "",
    job_title: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",    
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    institution_business_name: yup
      .string(),
    job_title: yup.string(),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, "Enter a valid phone number"),
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    country: yup.string(),
    zip: yup.string(),
  });

  const onSubmit = React.useCallback(
    (values, formProps) => {
      if (!executeRecaptcha) return;
      (async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);        

        try {
          const valid = await recaptchaSubmit(executeRecaptcha, drupalUrl);

          if (!valid) {
            setSnackMessage({
              type: "warning",
              message: "Failed Recaptcha",
            });
          } else {
            await postContactForm(drupalUrl, values, "gc");
            setSubmitting(false);
            setSnackMessage({
              type: "success",
              message: "We have received your information, we will contact you as soon as possible.",
            });
            resetForm();

            // if (SIGNUP_SUCCESS_PATH) {
            //   navigate(SIGNUP_SUCCESS_PATH);
            // }
          }
        } catch (e) {
          setSnackMessage({
            type: "error",
            message: "Something happened submitting your contact form.",
          });
          console.error(e);
          setSubmitting(false);
        }
      })(values, formProps);
    },
    [executeRecaptcha]
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <Paper component="form" className={classes.form} onSubmit={form.handleSubmit}>
      <Typography variant="h3">{title}</Typography>
      {body && (
        <Box>
          <Text html={body} />
        </Box>
      )}
      <TextField
        className={classes.inputBox}
        id="firstName"
        name="firstName"
        label="First Name"
        variant="outlined"
        value={form.values["firstName"]}
        onChange={form.handleChange}
        fullWidth
        error={form.touched["firstName"] && Boolean(form.errors["firstName"])}
        helperText={form.touched["firstName"] && form.errors["firstName"]}
        disabled={form.isSubmitting}
      />
      <TextField
        className={classes.inputBox}
        id="lastName"
        name="lastName"
        label="Last Name"
        variant="outlined"
        value={form.values["lastName"]}
        onChange={form.handleChange}
        fullWidth
        error={form.touched["lastName"] && Boolean(form.errors["lastName"])}
        helperText={form.touched["lastName"] && form.errors["lastName"]}
        disabled={form.isSubmitting}
      />
      <TextField
        className={classes.inputBox}
        id="email"
        name="email"
        label="Email"
        variant="outlined"
        value={form.values["email"]}
        onChange={form.handleChange}
        fullWidth
        error={form.touched["email"] && Boolean(form.errors["email"])}
        helperText={form.touched["email"] && form.errors["email"]}
        disabled={form.isSubmitting}
      />
      <TextField
        className={classes.inputBox}
        id="institution_business_name"
        name="institution_business_name"
        label="Institution"
        variant="outlined"
        value={form.values["institution_business_name"]}
        onChange={form.handleChange}
        fullWidth
        error={
          form.touched["institution_business_name"] &&
          Boolean(form.errors["institution_business_name"])
        }
        helperText={
          form.touched["institution_business_name"] &&
          form.errors["institution_business_name"]
        }
        disabled={form.isSubmitting}
      />
      <TextField
        className={classes.inputBox}
        id="job_title"
        name="job_title"
        label="Job Title"
        variant="outlined"
        value={form.values["job_title"]}
        onChange={form.handleChange}
        fullWidth
        error={form.touched["job_title"] && Boolean(form.errors["job_title"])}
        helperText={form.touched["job_title"] && form.errors["job_title"]}
        disabled={form.isSubmitting}
      />
      <TextField
        className={classes.inputBox}
        id="phone"
        name="phone"
        label="Phone Number"
        variant="outlined"
        value={form.values["phone"]}
        onChange={form.handleChange}
        fullWidth
        error={form.touched["phone"] && Boolean(form.errors["phone"])}
        helperText={form.touched["phone"] && form.errors["phone"]}
        disabled={form.isSubmitting}
      />
      <TextField
        className={classes.inputBox}
        id="street"
        name="street"
        label="Street"
        variant="outlined"
        value={form.values["street"]}
        onChange={form.handleChange}
        fullWidth
        error={form.touched["street"] && Boolean(form.errors["street"])}
        helperText={form.touched["street"] && form.errors["street"]}
        disabled={form.isSubmitting}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.inputBox}
            id="city"
            name="city"
            label="City"
            variant="outlined"
            value={form.values["city"]}
            onChange={form.handleChange}
            fullWidth
            error={form.touched["city"] && Boolean(form.errors["city"])}
            helperText={form.touched["city"] && form.errors["city"]}
            disabled={form.isSubmitting}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            className={classes.inputBox}
            id="state"
            name="state"
            label="State"
            variant="outlined"
            value={form.values["state"]}
            onChange={form.handleChange}
            fullWidth
            error={form.touched["state"] && Boolean(form.errors["state"])}
            helperText={form.touched["state"] && form.errors["state"]}
            disabled={form.isSubmitting}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            className={classes.inputBox}
            id="country"
            name="country"
            label="Country"
            variant="outlined"
            value={form.values["country"]}
            onChange={form.handleChange}
            fullWidth
            error={form.touched["country"] && Boolean(form.errors["country"])}
            helperText={form.touched["country"] && form.errors["country"]}
            disabled={form.isSubmitting}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            className={classes.inputBox}
            id="zip"
            name="zip"
            label="Zip"
            variant="outlined"
            value={form.values["zip"]}
            onChange={form.handleChange}
            fullWidth
            error={form.touched["zip"] && Boolean(form.errors["zip"])}
            helperText={form.touched["zip"] && form.errors["zip"]}
            disabled={form.isSubmitting}
          />
        </Grid>
      </Grid>
      <div className={classes.inputBox}>
        {form.isSubmitting ? (
          <CircularProgress size={24} className={classes.buttonProgress} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submitButton}
            disabled={form.isSubmitting}
          >
            Submit
          </Button>
        )}
      </div>
      <TermsConditionsDialog
        isOpen={isOpenTermsConditions}
        onClose={() => setIsOpenTermsConditions(false)}
      />
      <Snackbar
        open={snackMessage}
        autoHideDuration={6000}
        onClose={(e, reason) => setSnackMessage()}
      >
        <Alert
          onClose={(e) => setSnackMessage()}
          severity={snackMessage?.type}
          elevation={6}
          variant="filled"
        >
          {snackMessage?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default Form;
