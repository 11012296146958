import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import GeneticCounselorsContactForm from "../components/GeneticCounselorsContactForm";

function ContactGeneticCounselors({ pageContext }) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
      <GeneticCounselorsContactForm />
    </GoogleReCaptchaProvider>
  );
}

export default ContactGeneticCounselors;
